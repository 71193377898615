export const downloadFile = (response, name) => {
  const fileName = `${name}_report.xlsx`;

  const blob = new Blob([response.data], { type: "application/octet-stream" });
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.download = fileName;

  link.click();

  window.URL.revokeObjectURL(url);
  if (link.parentNode) {
    link.parentNode.removeChild(link);
  }
};
