<template>
  <el-popover v-model="visible" placement="bottom-end" width="300">
    <div class="download-report">
      <p>{{ title }}</p>
      <el-date-picker
        class="el-date-editor_filter"
        v-model="reportDates"
        type="daterange"
        format="dd.MM.yyyy"
        value-format="yyyy-MM-dd"
        start-placeholder="От"
        end-placeholder="До"
      />
      <el-button size="small" @click="handleCurrentChange">
        Скачать
      </el-button>
    </div>
    <el-button v-if="textButton" slot="reference" size="small">
      {{ textButton }}
    </el-button>
    <el-button
      v-else
      class="download-report__button"
      slot="reference"
      icon="el-icon-download"
      size="small"
    />
  </el-popover>
</template>

<script>
export default {
  name: "DownloadReport",
  props: {
    id: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    textButton: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      visible: false,
      reportDates: [new Date(), new Date()]
    };
  },
  methods: {
    handleCurrentChange() {
      const value = {
        dates: this.reportDates,
        id: this.id,
        name: this.name
      };
      this.$emit("download", value);
      this.visible = false;
    }
  }
};
</script>

<style scoped lang="scss">
.download-report {
  word-break: break-word;

  .el-date-editor {
    margin: 1rem 0;
  }

  .el-button {
    width: 100%;
    padding: 6px 0;
  }

  &__button {
    padding: 0;
    margin-left: 0.8rem;
    width: 3.4rem;
    height: 2.6rem;
    border-radius: 4px;
  }
}
</style>
